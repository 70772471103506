<template>
  <v-main class="mt-16">
    <section>
      <div class="container py-15 mt-2">
        <div class="row">
          <div class="col col-12">
            <h2 style="font-size: 25px">Aimguud</h2>
          </div>
          <div
            class="col-sm-6 col-md-4 col-lg-3 col-12"
            v-for="res in aimguud"
            :key="res.id"
          >
            <div
              class="
                pb-2
                br-10
                o-hidden
                v-card v-sheet v-sheet--outlined
                theme--light
                elevation-0
              "
            >
              <div class="position-relative">
                <div v-if="editImage == false" style="width: 100%">
                  <v-img
                    :src="
                      res.images != null &&
                      res.images != undefined &&
                      res.images[res.images.length - 1] != null &&
                      res.images[res.images.length - 1] != undefined
                        ? res.images[0].fileUrl
                        : '/placeholder.png'
                    "
                  ></v-img>
                </div>
                <div v-if="editImage == true">
                  <ImageAdd
                    style="width: 100%"
                    :objectPath="'/restaurant/' + res.id"
                  ></ImageAdd>
                </div>

                <div>
                  <span
                    class="
                      p-absolute
                      top-0
                      rounded-r-xl rounded-l-0
                      mt-3
                      v-chip v-chip--label
                      theme--light
                      v-size--default
                      primary
                    "
                    flat=""
                    ><span class="v-chip__content">Flat 30% Off</span>
                  </span>
                  <v-btn
                    @click="_inv"
                    icon
                    style="right: 0"
                    class="p-absolute top-0"
                    dark
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </div>

              <div
                class="pa-4 d-flex justify-space-between align-center"
                @click="_goDetail(res.id)"
              >
                <span
                  style=""
                  class="
                    d-inline-block
                    text-truncate
                    me-3
                    f-600
                    text-18
                    card-title
                  "
                  >{{ res.name }}</span
                >
                <div class="d-flex align-center card-rating">
                  <i
                    aria-hidden="true"
                    class="
                      v-icon
                      notranslate
                      me-1
                      mt-1
                      mdi mdi-star
                      theme--light
                      yellow--text
                      text--darken-2
                    "
                    style="font-size: 16px"
                  ></i>
                  <div style="line-height: 0">
                    <span class="font-weight-bold text-14">{{
                      res.rating
                    }}</span>
                    <span class="grey--text text--darken-1 text-14">/5</span>
                  </div>
                </div>
              </div>
              <p class="px-4 grey--text text--darken-1">
                <span class="primary--text f-600 me-1">${{ res.price }}</span>
              </p>
              <v-btn small @click="_newBulkDialog(res)">
                <v-icon color="darken-2" text>B+</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 col-12">
            <div
              class="
                br-10
                o-hidden
                v-card v-sheet v-sheet--outlined
                theme--light
                elevation-0
              "
            >
              <div class="position-relative">
                <div @click="addItem()">
                  <v-img
                    src="/add.png"
                    class="mx-auto"
                    style="width: 50%; cursor: pointer"
                  ></v-img>
                </div>
              </div>
              <div class="px-4">
                <v-text-field
                  label="Name"
                  v-model="name"
                  outlined
                  dense
                  @keyup.enter.prevent="addItem"
                ></v-text-field>
              </div>
              <div class="px-4 mt-n4">
                <v-text-field
                  label="Price"
                  v-model="price"
                  outlined
                  dense
                  @keyup.enter.prevent="addItem"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-model="newBulkDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline">
          Газрын мэдээлэл бөөнөөр оруулаx</v-card-title
        >
        <v-card-text v-if="selectedAimag">
          {{ selectedAimag.name }}
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-textarea
                v-model="bulkText"
                outlined
                color="rgba(0,0,0,.6)"
                class="
                  font-size-input
                  border
                  text-light-input
                  border-radius-md
                  mt-2
                "
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn class="blue darken-1" @click="_saveBulk"> Xадгалаx </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
const fb = require("../../firebaseConfig.js");
import ImageAdd from "../tours/ImageAdd.vue";

export default {
  components: {
    ImageAdd,
  },
  data() {
    return {
      aimguud: null,
      editImage: false,
      name: null,
      price: null,
      newBulkDialog: false,
      selectedAimag: null,
      dcollection: "countries/Mongolia/locations",
      bulkText:null,
    };
  },
  created() {
    fb.db
      .collection("countries/Mongolia/cities")
      .orderBy("index", "asc")
      .onSnapshot((querySnapshot) => {
        this.aimguud = [];
        querySnapshot.forEach((doc) => {
          let city = doc.data();
          city.id = doc.id;
          city.ref = doc.ref;
          this.aimguud.push(city);
        });
      });
  },
  methods: {
    _saveBulk() {
      if (this.bulkText != null) {
        var batch = fb.db.batch();
        if (this.bulkText != null && this.bulkText != "") {
          var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
          if (arrayOfLines != null && arrayOfLines.length > 0) {
            arrayOfLines.forEach((value) => {
              console.log(value.trim());
              var xx = fb.db.collection(this.dcollection).doc();
              var data = {
                name: value.trim(),
                selectedCity: this.selectedAimag.ref,
                selectedCityName: this.selectedAimag.name,
              };
              batch.set(xx, data);
            });
          }
        }
        batch.commit().then(() => {
          this.bulkText = null;
          this._closeEdit();
        });
      }
    },
    _closeEdit() {
      this.newBulkDialog = false;
      this.bulkText = null;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    _newBulkDialog(aimag) {
      this.selectedAimag = aimag;
      this.newBulkDialog = true;
    },
    _goDetail(id) {
      this.$router.push({
        name: "RestaurantDetail",
        params: { restaurantId: id },
      });
    },
    _inv() {
      this.editImage = !this.editImage;
    },
    async addItem() {
      if (this.name && this.price) {
        await db.collection("aimguud/").add({
          name: this.name,
          price: this.price,
          createdAt: new Date(),
          rating: null,
        });
        this.name = null;
        this.price = null;
      }
    },
  },
};
</script>
<style>
.p-absolute {
  position: absolute !important;
}
.top-0 {
  top: 0 !important;
}
.text-18 {
  font-size: 20px !important;
}

.f-600 {
  font-weight: 400;
}
.text-14,
h6 {
  font-size: 14px;
}
</style>